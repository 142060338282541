/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)

// Libs
import "bootstrap";
import "bootstrap-select/dist/css/bootstrap-select.min.css";

// Libs JS
import "bootstrap-select/dist/js/bootstrap-select.min.js";
import { initPhoneInputs } from './javascript/phone-input';

// Sass

import "./styles/scss/base/_reset.scss";
import "./styles/scss/base/_html5.scss";
import "./styles/scss/base/_functions.scss";
import "./styles/scss/base/_utils.scss";

import "./styles/scss/config/_fonts.scss";
import "./styles/scss/config/_variables.scss";

import "./styles/scss/generic/_common.scss";
import "./styles/scss/generic/_buttons.scss";

import "./styles/scss/partials/_cms.scss";
import "./styles/scss/partials/_card-general.scss";
import "./styles/scss/partials/_breaking-news.scss";
import "./styles/scss/partials/_alert.scss";
import "./styles/scss/partials/_greeting.scss";
import "./styles/scss/partials/_cms-with-menu.scss";

import "./styles/scss/template/_home.scss";
import "./styles/scss/template/_listing.scss";
import "./styles/scss/template/_detail.scss";
import "./styles/scss/template/_contact.scss";
import "./styles/scss/template/_connexion.scss";
import "./styles/scss/template/_inscription.scss";
import "./styles/scss/template/_comparator.scss";
import "./styles/scss/template/_client-dashboard.scss";
import "./styles/scss/template/_replacement.scss";
import "./styles/scss/template/_mes-commandes.scss";
import "./styles/scss/template/_cart.scss";
import "./styles/scss/template/_quote.scss";
import "./styles/scss/template/_orders.scss";
import "./styles/scss/template/_bbgr-info-modal.scss";
import "./styles/scss/template/_bon_de_reductions.scss";
import "./styles/scss/template/_error-page.scss";
import "./styles/scss/template/_password.scss";
import "./styles/scss/template/_optical-folder.scss";
import "./styles/scss/template/_prescription.scss";
import "./styles/scss/template/_warranty.scss";
import "./styles/scss/template/_modal_vto.scss"
import "./styles/scss/template/_hero-wrapper.scss";
import "./styles/scss/partials/_banner.scss";

import "./styles/scss/module/_form.scss";
import "./styles/scss/module/_sticky-compare.scss";
import "./styles/scss/module/_beautified-select.scss";
import "./styles/scss/module/_cookies-headband.scss";
import "./styles/scss/module/_modal-choices.scss";
import "./styles/scss/module/_modal-choices-new.scss";
import "./styles/scss/module/_range.scss";
import "./styles/scss/module/_phone-input.scss"

import "./styles/scss/layouts/_header.scss";
import "./styles/scss/layouts/_footer.scss";
import "./styles/scss/layouts/_main.scss";

document.addEventListener('DOMContentLoaded', () => {
    initPhoneInputs();
});
