import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';

export function initPhoneInputs() {

    // Initialize for all phone inputs
    const phoneInputs = document.querySelectorAll('.mobile-phone, .landline-phone, #registration_contactInfo_mobilePhoneNumber, #registration_contactInfo_landlinePhoneNumber, #personal_information_contactInfo_mobilePhoneNumber, #personal_information_contactInfo_landlinePhoneNumber');
    
    phoneInputs.forEach(input => {
        if (!(input instanceof HTMLInputElement)) return;
        
        // Skip if already initialized
        if (input.dataset.phoneInitialized) return;
        
        const iti = intlTelInput(input, {
            initialCountry: document.querySelector("html")?.getAttribute("lang") ?? 'fr',
            separateDialCode: false,
            countryOrder: ['fr', 'it', 'be', 'ch', 'lu'],
            formatOnDisplay: false,
            autoPlaceholder: 'aggressive',
            container: input.closest('.phone-input-wrapper')
        });

        // Store instance on input element for later access
        input.iti = iti;
        
        // Mark as initialized
        input.dataset.phoneInitialized = 'true';

        // Update hidden country input when country changes
        input.addEventListener('countrychange', () => {
            const dialCode = '+' + iti.getSelectedCountryData().dialCode;
            if (countryInput) {
                countryInput.value = dialCode;
            }
            input.dataset.prefix = dialCode;
        });

        // Handle input
        input.addEventListener('focus', () => {
            if(input.value === '') {
                input.value = '+' + iti.getNumber()+iti.getSelectedCountryData().dialCode;
            }
        });

        input.addEventListener('blur', () => {
            if(input.value === '+' + iti.getNumber()+iti.getSelectedCountryData().dialCode) {
                input.value = '';
            }
        });


        // Add validation on blur
        /*input.addEventListener('blur', () => {
            const dialCode = '+' + iti.getSelectedCountryData().dialCode;
            if (countryInput) {
                countryInput.value = dialCode;
            }
            input.dataset.prefix = dialCode;
            
            if (input.value.trim()) {
                if (!iti.isValidNumber()) {
                    input.classList.add('border-error');
                    if (!document.getElementById('error-message_' + input.id)) {
                        const errorMsg = document.createElement('div');
                        errorMsg.setAttribute('class', 'error-message');
                        errorMsg.setAttribute('id', 'error-message_' + input.id);
                        errorMsg.innerHTML = "Numéro de téléphone invalide";
                        input.parentElement?.appendChild(errorMsg);
                    }
                } else {
                    input.classList.remove('border-error');
                    document.getElementById('error-message_' + input.id)?.remove();
                    input.value = iti.getNumber().replace(dialCode, ''); // Store number without country code
                }
            }
        });*/

    });
} 